<script>
  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import ChevronRight from '../common/icons/ChevronRight.svelte'
  import ChevronLeft from '../common/icons/ChevronLeft.svelte'

  let ANIMATION_DURATION_MS = 200

  export let imageUrls = []
  export let imageClass, altBaseText

  let swipeElements = [], swipeWrapper, touchStartingXOffset, touchStartingYOffset
  let currentSlide = 0
  let transitionDurationMs = 0
  let showControls = false
  let lastMovingOffsetX = 0
  let lastMovingOffsetY = 0
  let observer

  const nextSlide = () => {
    if(currentSlide >= swipeElements.length - 1) {
      return
    }

    currentSlide = currentSlide + 1
    setSlide(currentSlide)
  }

  const prevSlide = () => {
    if(currentSlide < 1) {
      return
    }

    currentSlide = currentSlide - 1
    setSlide(currentSlide)
  }

  const changeItem = (index) => {
    currentSlide = index
    setSlide(currentSlide)
  }

  const onMoveStart = (e) => {
    e.stopImmediatePropagation();
    e.stopPropagation();

    transitionDurationMs = 0

    touchStartingXOffset = offsetXFromEvent(e)
    touchStartingXOffset = touchStartingXOffset + currentSlide * containerWidth()

    touchStartingYOffset = offsetYFromEvent(e)

    loadNextImage()

    addListeners()
  }

  const onEnd = (e) => {
    if(e && e.cancelable) { e.preventDefault(); }

    transitionDurationMs = ANIMATION_DURATION_MS

    const distanceFromStart = touchStartingXOffset - currentSlide * containerWidth() - lastMovingOffsetX

    if(distanceFromStart > containerWidth() / 5 ) {
      nextSlide()
    }
    else if(distanceFromStart < -containerWidth() / 5) {
      prevSlide()
    }

    setSlide(currentSlide)
    removeListeners()
  }

  const setSlide = (slide) => {
    transitionDurationMs = ANIMATION_DURATION_MS
    setElementPositions(slide * containerWidth())
    loadCurrentImage()
  }

  const onMove = (e) => {
    lastMovingOffsetX = offsetXFromEvent(e)
    lastMovingOffsetY = offsetYFromEvent(e)

    const distanceXFromStart = touchStartingXOffset - lastMovingOffsetX
    const distanceYFromStart = touchStartingYOffset - lastMovingOffsetY

    if(Math.abs(distanceXFromStart - currentSlide * containerWidth()) > Math.abs(distanceYFromStart)) {
      e.preventDefault()
      setElementPositions(distanceXFromStart)
    }
  }

  const setElementPositions = (distanceFromStart) => {
    swipeElements.forEach((element, i) => {
      element.style.transform = `translate3d(${i * containerWidth() - distanceFromStart}px, 0, 0)`
      element.style.transitionDuration = transitionDurationMs + 'ms'
      element.style.transitionTimingFunction = 'cubic-bezier(0.4, 0, 1, 1);'
    });
  }

  const offsetYFromEvent = (e) => {
    if(e.touches) {
      return e.touches[0].pageY
    }

    return e.offsetY
  }

  const offsetXFromEvent = (e) => {
    if(e.touches) {
      return e.touches[0].pageX
    }

    return e.offsetX
  }

  const containerWidth = () => {
    if(!swipeWrapper) {
      return 0
    }

    return swipeWrapper.clientWidth
  }

  const onVisible = (entries, observer) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        loadCurrentImage()
        observer.unobserve(swipeWrapper);
      }
    })
  }

  onMount(() => {
    swipeElements = swipeWrapper.querySelectorAll('.swipe-element')
    setElementPositions(0)

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        setSlide(currentSlide)
      })
    }

    observer = new IntersectionObserver(onVisible, {});
    observer.observe(swipeWrapper);
  });

  const loadNextImage = () => {
    if(swipeElements.length > currentSlide + 1) {
      const img = swipeWrapper.querySelectorAll('img')[currentSlide + 1]
      img.src = img.dataset.src
    }
  }

  const loadCurrentImage = () => {
    if(swipeWrapper) {
      const img = swipeWrapper.querySelectorAll('img')[currentSlide]
      img.src = img.dataset.src
    }
  }

  const onMouseEnter = () => {
    showControls = true
  }

  const onMouseLeave = () => {
    showControls = false
  }

  const removeListeners = () => {
    window.removeEventListener('mousemove', onMove);
    window.removeEventListener('mouseup', onEnd);

    window.removeEventListener('touchmove', onMove);
    window.removeEventListener('touchend', onEnd);
  }

  const addListeners = () => {
    window.addEventListener('mousemove', onMove);
    window.addEventListener('mouseup', onEnd);

    window.addEventListener('touchmove', onMove, {passive:false});
    window.addEventListener('touchend', onEnd, {passive:false});
  }
</script>

<div bind:this={swipeWrapper} class="relative h-64 overflow-hidden rounded-lg sm:h-40" on:mouseenter={onMouseEnter} on:mouseleave={onMouseLeave}>
  {#each imageUrls as src, idx}
    <div class="absolute top-0 left-0 w-full h-64 rounded-lg sm:h-40 sm:w-56 swipe-element">
      <img data-src={src} alt={`${altBaseText} - Photo ${idx + 1}`} decoding="async" class={imageClass}>
    </div>
  {/each}

  <div class="absolute w-full h-full" on:touchstart={onMoveStart} on:mousedown={onMoveStart}> </div>

  {#if showControls}
    <div in:fade="{{duration: 100}}" class="absolute top-0 bottom-0 z-10 w-full mx-0 my-auto">
      {#if currentSlide > 0}
        <div class="absolute top-0 bottom-0 w-6 h-6 p-1 mx-0 my-auto rounded-full cursor-pointer bg-white/80 left-2" on:click={prevSlide}><ChevronLeft/></div>
      {/if}

      {#if currentSlide < swipeElements.length - 1 }
        <div class="absolute top-0 bottom-0 w-6 h-6 p-1 mx-0 my-auto rounded-full cursor-pointer bg-white/80 right-2" on:click={nextSlide}><ChevronRight/></div>
      {/if}
    </div>
  {/if}

  <div class="absolute flex justify-center w-full bottom-2">
    {#each swipeElements as _, i }
      <div class="rounded-full w-2 h-2 mx-1 transition-all {currentSlide == i ? 'bg-white/80 scale-[1.15]' : 'bg-gray-700/80 scale-75'}" on:click={() => {changeItem(i)}}></div>
    {/each}
  </div>

</div>
