import { writable, get } from "svelte/store";

const DURATION = 5000;

export const notification = writable({});

export const processePotentialNotification = (data) => {
  if (data.notification) {
    if (data.notification.type == "notice") {
      notification.set({ notice: data.notification.message });
    }
    if (data.notification.type == "alert") {
      notification.set({ alert: data.notification.message });
    }

    setTimeout(() => {
      notification.set({});
    }, DURATION);
  }
};
