<script context="module">
  export function getHeaders() {
    let headers = new window.Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    })

    const csrfToken = document.head.querySelector("[name='csrf-token']").content
    if (csrfToken) { headers.append('X-CSRF-Token', csrfToken) }

    return headers
  }
</script>
